@import url('https://fonts.googleapis.com/css2?family=Nunito');

.divExternaGaleria .image-gallery{
  margin: 0 auto;
  transition: all 1s ease;
}

@media (max-width: 768px) {

  .divExternaGaleria-header {
    font-size: 20px;
  }

  .divExternaGaleria-buttons li {
    display: block;
    margin: 10px 0;
  }

  .divExternaGaleria-buttons li + li {
    padding: 0;
  }
}

@media (max-width: 1024px) {
  .divExternaGaleria .image-gallery {
    width: 100%;
  }
}